import { Icon } from "@iconify/react";
import {
  ChangeEvent,
  HTMLInputTypeAttribute,
  useEffect,
  useRef,
  useState,
} from "react";
import React from "react";
import { ZodError } from "zod";

interface FieldProps {
  index?: number;
  label: string;
  name: string;
  type?: HTMLInputTypeAttribute;
  error?: boolean;
  errors?: ZodError | any;
  errorMessage?: string;
  defaultValue?: string;
  value?: string;
  customErrors?: {
    [key: string]: {
      en: string;
      fr: string;
    };
  };
  onChange?: (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
  options?: string[] | string[][];
  useOptionValueAsKey?: boolean;
  helper?: React.ReactNode;
  min?: number;
  max?: number;
  disabled?: boolean;
  noBlankOption?: boolean;
  icon?: string;
  autoComplete: string
}

export function Field({
  index,
  name,
  label,
  type: baseType = "text",

  customErrors,
  defaultValue,
  value,
  onChange,
  options,
  useOptionValueAsKey,
  helper,
  error,
  errors,
  errorMessage,
  min,
  max,
  disabled = false,
  noBlankOption = false,
  icon,
  autoComplete = ''
}: FieldProps) {
  const [type, setType] = useState(baseType);
  const selectRef = useRef<HTMLSelectElement>(null);
  const fieldRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    let isFirstError = false;
    errors?.issues?.filter((errors, i: number) => {
      if (i > 0) return null;
      if (
        index !== undefined &&
        errors.path.includes(name) &&
        errors.path.includes(index)
      ) {
        return (isFirstError = true);
      }
      if (name.includes(".")) {
        const nameParts = name.split(".");
        let isMatch = true;
        nameParts.map((part, i) =>
          part !== errors.path[i] ? (isMatch = false) : null,
        );
        return (isFirstError = isMatch ? true : false);
      }
      return (isFirstError = errors.path.includes(name) ? true : false);
    })[0];

    if (isFirstError) {
      selectRef?.current?.focus();
      selectRef?.current?.scrollIntoView({
        block: "center",
        behavior: "smooth",
      });
      fieldRef?.current?.focus();
      fieldRef?.current?.scrollIntoView({
        block: "center",
        behavior: "smooth",
      });
      // window.scrollBy(0, -110)
    }
  }, [errors]);

  const zodError = errors?.issues?.filter((errors) => {
    if (index !== undefined) {
      return errors.path.includes(name) && errors.path.includes(index);
    }
    if (name.includes(".")) {
      const nameParts = name.split(".");
      let isMatch = true;
      nameParts.map((part, i) =>
        part !== errors.path[i] ? (isMatch = false) : null,
      );
      return isMatch;
    }
    return errors.path.includes(name);
  })[0];

  let zodErrorMessage = "";
  if (zodError) {
    try {
      const json = JSON.parse(zodError.message);
      zodErrorMessage = json["en"];
    } catch (e) {
      zodErrorMessage = zodError.message;
    }
  }

  const isError = error || zodError;

  return (
    <div>
      <label
        htmlFor={`f_${name}`}
        className="text-gray-600 text-sm flex justify-between items-bottom"
      >
        {label}
        <span>{helper}</span>
      </label>
      <div className={`relative`} style={{ marginBottom: "0.5rem" }}>
        {icon && (
          <div
            className="absolute left-0 top-0 bottom-0 flex items-center px-2 text-gray-400"
            style={{ marginTop: "-0.5rem" }}
          >
            <Icon icon={icon} fontSize={24} />
          </div>
        )}
        {baseType === "select" ? (
          <select
            name={name}
            id={`f_${name}`}
            style={{ padding: "14px 0 13px 0" }}
            className={`bg-white dark:bg-black/10 rounded border w-full ${icon ? "indent-8" : "indent-4"
              } ${isError ? "border-red-300 bg-red-100" : "border-gray-300"}`}
            onChange={onChange}
            defaultValue={defaultValue}
            value={value}
            ref={selectRef}
            disabled={disabled}
          >
            {!noBlankOption && <option value=""></option>}
            {options?.map((option, i) => {
              const { optionValue, optionText } = Array.isArray(option)
                ? { optionValue: option[0], optionText: option[1] }
                : { optionValue: option, optionText: option };

              return (
                <option
                  key={useOptionValueAsKey ? optionValue : i}
                  value={optionValue}
                >
                  {optionText}
                </option>
              );
            })}
          </select>
        ) : baseType === "checkbox" ? (
          <div className="border">
            <input
              type="checkbox"
              name={name}
              id={`f_${name}`}
              onChange={onChange}
              defaultValue={defaultValue}
              value={value}
              ref={fieldRef}
              disabled={disabled}
            />
          </div>
        ) : (
          <>
            <input
              type={type}
              name={name}
              id={`f_${name}`}
              style={{ padding: "12px 0" }}
              className={`bg-white read-only:bg-zinc-200 dark:bg-black/10 rounded border  ${icon ? "indent-9" : "indent-4"
                } w-full ${isError ? "border-red-300 bg-red-100" : "border-gray-300"
                }`}
              onChange={onChange}
              defaultValue={defaultValue}
              value={value}
              min={min}
              max={max}
              ref={fieldRef}
              readOnly={disabled}
              autoComplete={autoComplete}
            />
            {baseType === "password" && (
              <button
                type="button"
                className="absolute top-0 right-2  bottom-0"
                onClick={() =>
                  setType(type === "password" ? "text" : "password")
                }
              >
                <Icon
                  icon={type === "text" ? "mdi:eye-lock-open" : `mdi:eye-lock`}
                  fontSize={24}
                  className="text-slate-500 m-0"
                />
              </button>
            )}
          </>
        )}
      </div>
      {errors && (
        <div className="text-red-500 text-sm">
          {zodError ? zodErrorMessage : errorMessage}
        </div>
      )}
    </div>
  );
}
